import React from "react";
import { Card, CardBody, Button, Row, Col } from "reactstrap";
import notAuthImg from "../../../assets/img/pages/not-authorized.png";
import { clearUser } from "../../../utility/store";
import { history } from "../../../history";

class NotAuthorized extends React.Component {
  backButtonHandler() {
    if (this.props.inLinePage) {
      history.push("/dashboard");
    } else {
      clearUser()
    }
  }
  render() {
    return (
      <Row className="m-0">
        <Col sm="12">
          <Card className="auth-card bg-transparent shadow-none rounded-0 mb-0 w-100">
            <CardBody className="text-center">
              <img
                src={notAuthImg}
                alt="notAuthImg"
                className="img-fluid align-self-center mt-75"
              />
              <h1 className="font-large-2 my-2 text-dark">
                You are not authorized!
              </h1>
              <p className="pt-2 mb-0">
                You don't have permissions to access this page.
              </p>
              <p className="pb-2">
                For more information get in touch with the admin.
              </p>
              <Button.Ripple
                color="primary"
                size="lg"
                className="mt-2"
                onClick={()=>this.backButtonHandler()}
              >
                {this.props.inLinePage ? "Back to Dashboard" : "Back to Login"}
              </Button.Ripple>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}
export default NotAuthorized;
